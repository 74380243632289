import React from "react";
import Image from "next/image";
import ErrorImageSrc from "@/assets/error.png";

export default function ErrorImage() {
  return (
    <div>
      {ErrorImageSrc && (
        <Image
          className="w-[100%] max-w-[450px] object-cover sm:w-[auto]"
          src={ErrorImageSrc}
          alt="Kontakten er løs"
          width={300}
          height={300}
        />
      )}
    </div>
  );
}
