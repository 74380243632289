import type { JSX } from "react";
export type PriceProps<
  G extends {
    quantity?: number | null;
    price?: { gross?: number | null } | null;
    subTotal?: { gross?: number | null } | null;
  } = {
    quantity?: number | null;
    price?: { gross?: number | null } | null;
    subTotal?: { gross?: number | null } | null;
  },
> = {} & G;

function formatPrice(price?: number | null) {
  if (!price) return "-";
  return Number.isNaN(price) ? "" : `${price},-`;
}

export function Price<
  G extends {
    quantity?: number | null;
    price?: { gross?: number | null } | null;
    subTotal?: { gross?: number | null } | null;
  },
>({
  quantity,
  price,
  subTotal,
  ...rest
}: PriceProps<G> & JSX.IntrinsicElements["div"]) {
  return (
    <div {...rest}>
      {quantity &&
      price?.gross &&
      subTotal &&
      price.gross * quantity !== subTotal.gross ? (
        <span className="mr-4 text-right text-gray-600 line-through">
          {formatPrice(price?.gross)}
        </span>
      ) : null}
      <span className="inline-block w-[6ch] text-right">
        {formatPrice(subTotal?.gross)}
      </span>
    </div>
  );
}
