import(/* webpackMode: "eager", webpackExports: ["SanityAssetImage"] */ "/vercel/path0/apps/netthandel/src/cms/images/SanityAssetImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel"] */ "/vercel/path0/apps/netthandel/src/components/Carousel.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/netthandel/src/components/Rosett.svg");
;
import(/* webpackMode: "eager", webpackExports: ["SearchRefinement"] */ "/vercel/path0/apps/netthandel/src/search/components/SearchRefinement.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccordionSection"] */ "/vercel/path0/apps/netthandel/src/sections/AccordionSection/AccordionSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SubscriptionCampaignFocus"] */ "/vercel/path0/apps/netthandel/src/sections/SubscriptionCampaignFocusSection/SubscriptionCampaignFocus.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Carousel/useCarousel.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Modal/Modal.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Table/TableRows.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Tabs/Tabs.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Tooltip/Tooltip.js");
