import { useId, useState } from "react";

export function useAccordion(initialValue: boolean = false) {
  const [isExpanded, setIsExpanded] = useState(initialValue);
  const id = useId();
  const open = () => setIsExpanded(true);
  const close = () => setIsExpanded(false);
  const toggle = () => setIsExpanded((prev) => !prev);
  const regionId = `${id}-region`;
  const buttonId = `${id}-button`;

  return {
    isExpanded,
    toggle,
    open,
    close,
    aria: {
      button: {
        id: buttonId,
        "aria-controls": regionId,
        "aria-expanded": isExpanded,
      },
      panel: {
        id: regionId,
        "aria-labelledby": buttonId,
      },
    },
  };
}
