import { ExperimentGroup } from "./group";

export type FeatureStatus =
  | "Aktiv"
  | "Inaktiv"
  | "A"
  | "B"
  | "Preview"
  | "Preview A"
  | "Preview B";

export type FeatureStatuses = {};

export const isFeatureEnabled = (
  status: FeatureStatus | null,
  experimentGroup: ExperimentGroup,
) => {
  if (
    status === "Aktiv" ||
    (status === "A" && experimentGroup === ExperimentGroup.A) ||
    (status === "B" && experimentGroup === ExperimentGroup.B)
  ) {
    return true;
  }

  if (process.env.VERCEL_ENV === "preview") {
    if (
      status === "Preview" ||
      (status === "Preview A" && experimentGroup === ExperimentGroup.A) ||
      (status === "Preview B" && experimentGroup === ExperimentGroup.B)
    ) {
      return true;
    }
  }

  return false;
};
