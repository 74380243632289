"use client";
import { useHits } from "react-instantsearch-core";

type Template = `${string}${"{numberOfHits}" | "{query}"}${string}` | string;

export type NumberOfHitsProps = {
  template: Template;
  blankQuery?: Template;
  noHits?: Template;
};

export function NumberOfHits({
  template,
  blankQuery,
  noHits,
}: NumberOfHitsProps) {
  const { results } = useHits();
  const { nbHits = 0, query = "" } = results ?? { nbHits: 0, query: "" };
  if (blankQuery && !query)
    return blankQuery
      .replace("{numberOfHits}", `${nbHits}`)
      .replace("{query}", `${query}`);
  if (noHits && nbHits === 0)
    return noHits
      .replace("{numberOfHits}", `${nbHits}`)
      .replace("{query}", `${query}`);
  return template
    .replace("{numberOfHits}", `${nbHits}`)
    .replace("{query}", `${query}`);
}
