export const getActiveRefinements = (
  filters?: string,
): Record<string, true> => {
  if (!filters) return {};
  return filters
    ?.split(" ")
    .filter((filter) => filter.includes(":"))
    .reduce<Record<string, true>>(
      (acc, cur) => ({ ...acc, [cur.split(":")[0]]: true }),
      {},
    );
};
