import type * as IconTypes from "react-icons/pi";
import type { Icon } from "../sanity.types";

type IconName = keyof typeof IconTypes;

export function capitalizeFirstLetter(string?: string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const getIconName = ({ icon, weight }: Icon): IconName =>
  `Pi${capitalizeFirstLetter(icon)}${
    weight && weight !== "regular" ? capitalizeFirstLetter(weight) : ""
  }` as IconName;
