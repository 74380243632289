import Link, { LinkProps } from "next/link";

import type { JSX } from "react";

export type AnchorProps = Omit<JSX.IntrinsicElements["a"], "ref" | "href"> &
  Omit<LinkProps, "as"> & {
    linkAs?: LinkProps["as"];
  };

export const Anchor = ({ href, children, linkAs, ...rest }: AnchorProps) => {
  if (typeof href === "string" && href.startsWith("https://"))
    return (
      <a href={href} rel="noreferer noopen" {...rest}>
        {children}
      </a>
    );
  return (
    <Link {...rest} href={href} as={linkAs}>
      {children}
    </Link>
  );
};
