import { createInsightsMiddleware } from "instantsearch.js/es/middlewares";
import { useInstantSearch } from "react-instantsearch";
import { useLayoutEffect } from "react";
import tracker from "@/tracking/tracker";
import { searchInsightsEnabled } from "../client";

export function SearchTracker() {
  const { addMiddlewares } = useInstantSearch();

  useLayoutEffect(() => {
    const middleware = createInsightsMiddleware({
      insightsClient: window.aa,
      onEvent: (event, _aa) => {
        const {
          eventType,
          payload,
          hits,
          widgetType,
          insightsMethod,
          attribute,
          eventModifier,
        } = event;

        // Send the event to Algolia
        if (insightsMethod && searchInsightsEnabled) {
          window.aa(insightsMethod, payload);
        }

        const searchPayload = {
          search: {
            ...payload,
            method: insightsMethod,
            attribute,
            eventModifier,
            widgetType,
          },
          items: (hits ?? []).map(
            ({ title, objectID: sku, price, __queryID, __position }) => ({
              item_id: sku ?? "",
              item_name: title ?? "",
              price: Number(price),
              item_category: "product",
              index: __position,
              quantity: 1,
              item_variant: sku ?? "",
              item_list_id: __queryID,
            }),
          ),
        };

        if (insightsMethod === "viewedObjectIDs")
          tracker.trackEvent({
            eventName: "view_item_list",
            eventType,
            ...searchPayload,
          });
        else if (insightsMethod === "clickedFilters")
          tracker.trackEvent({
            eventName: "click",
            eventType: insightsMethod,
            ...searchPayload,
          });
      },
    });

    return addMiddlewares(middleware);
  }, [addMiddlewares]);

  return null;
}
