import { Quote as QuoteTypes } from "@/cms/types/objects";
import { FC, type JSX } from "react";
import classNames from "classnames";

export type QuoteProps = {
  value: QuoteTypes;
};

export const Quote: FC<QuoteProps & JSX.IntrinsicElements["figure"]> = ({
  value: { text, sender, variant },
  className,
  ...rest
}) => {
  if (variant === "indented") {
    return (
      <figure
        className={classNames(
          "border-l-4 border-[var(--text-primary-contrast)]",
          className,
        )}
        data-testid="quote"
        {...rest}
      >
        {text && (
          <blockquote className="pl-2 font-normal italic">{`«${text}»`}</blockquote>
        )}
        {sender && (
          <figcaption className="mt-2 pl-2 font-normal">{`– ${sender}`}</figcaption>
        )}
      </figure>
    );
  }
  return (
    <figure className={classNames(className)} data-testid="quote" {...rest}>
      {text && <blockquote className="font-semibold">{`«${text}»`}</blockquote>}
      {sender && (
        <figcaption className="mt-3 font-normal">{`– ${sender}`}</figcaption>
      )}
    </figure>
  );
};
