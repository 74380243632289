"use client";
import {
  SearchBar as SearchComponent,
  getBookSource,
  getSubscriptionSource,
} from "@cappelendamm/product-search";
import {
  searchClient,
  bookIndex,
  querySuggestionsIndex,
  searchInsightsEnabled,
  subscriptionIndex,
} from "./client";
import { FC, ReactNode } from "react";
import { SearchTracker } from "./components/SearchTracker";
// import { InstantSearchNext } from "react-instantsearch-nextjs";
import { InstantSearch } from "react-instantsearch"; // Replace with one above at a later point where it doesn't render children twice.

export const SearchBar = () => {
  return (
    <SearchComponent
      searchClient={searchClient}
      querySuggestionsIndex={querySuggestionsIndex}
      querySources={({ query }) => [
        getSubscriptionSource({
          query,
          indexName: subscriptionIndex,
          searchClient,
        }),
        getBookSource({ query, indexName: bookIndex, searchClient }),
      ]}
    />
  );
};

export const SearchContext: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={bookIndex}
      insights={searchInsightsEnabled}
      future={{ preserveSharedStateOnUnmount: true }}
      routing={false}
    >
      {children}
      <SearchTracker />
    </InstantSearch>
  );
};
