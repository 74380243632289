"use client";
import { ConfigureProps } from "react-instantsearch";
import { Facet } from "./Facet";
import classNames from "classnames";
import { getActiveRefinements } from "./getActiveRefinments";
import type { JSX } from "react";

export type FacetListProps = {
  configure?: ConfigureProps;
  initialExpanded?: boolean;
};

export function FacetList({
  configure,
  className,
  initialExpanded = true,
  ...rest
}: FacetListProps & JSX.IntrinsicElements["div"]) {
  const activeRefinements = getActiveRefinements(configure?.filters);
  return (
    <div className={classNames("auto-rows-min gap-4", className)} {...rest}>
      {!activeRefinements.format ? (
        <Facet
          config={{
            attribute: "format",
            operator: "and",
            limit: 3,
            showMore: true,
          }}
          initialExpanded={initialExpanded}
          title="Format"
        />
      ) : null}
      {!activeRefinements.authors ? (
        <Facet
          config={{
            attribute: "authors",
            operator: "and",
            limit: 3,
            showMore: true,
            searchable: true,
          }}
          initialExpanded={initialExpanded}
          title="Forfatter"
        />
      ) : null}
      {!activeRefinements.language ? (
        <Facet
          config={{
            attribute: "language",
            operator: "and",
            limit: 3,
            showMore: true,
          }}
          initialExpanded={initialExpanded}
          title="Språk"
        />
      ) : null}
      {!activeRefinements.seriesName ? (
        <Facet
          config={{
            attribute: "seriesName",
            operator: "and",
            limit: 5,
            showMore: true,
            searchable: true,
          }}
          initialExpanded={initialExpanded}
          title="Serienavn"
        />
      ) : null}
    </div>
  );
}
