"use client";

import { FC, type JSX } from "react";
import classNames from "classnames";
import { TrackingItem, TrackingSection } from "@/tracking/types";
import { getTrackingDataset } from "@/tracking/utils";
import { SectionProps } from "../Section";
import { Accordion } from "@/components/Accordion";
import { PortableText } from "@/portable-text/PortableText";
import { ContentData } from "@/cms/types";
import { AccordionSectionQueryResult } from "@/cms/sanity.types";

export type AccordionSectionProps = ContentData<AccordionSectionQueryResult> &
  Omit<SectionProps, "_id">;

export const AccordionSection: FC<
  AccordionSectionProps & JSX.IntrinsicElements["section"]
> = ({
  data: { _id, internalTitle, accordions, title, container, variant },
  index,
}) => {
  const sectionTracking = getTrackingDataset<
    TrackingSection & Partial<TrackingItem>
  >({
    sectionId: _id ?? "section-id-unknown",
    sectionName:
      internalTitle?.replaceAll(" ", "-") ?? "accordion_section_name",
    sectionType: "accordionSection",
    sectionIndex: index,
  });
  return (
    <section
      className={classNames("bg-body w-full sm:overflow-visible")}
      {...sectionTracking}
    >
      <div className="xs:p-3 flex w-full flex-col items-center sm:p-3">
        <div
          className={classNames("w-full", {
            "lg:w-body lg:mx-body": container === "default",
          })}
        >
          <div className="lg:w-body flex w-full flex-col gap-3 lg:mx-auto">
            <h3
              className={classNames("heading-03", {
                "text-[var(--fg-primary)]": variant === "color",
              })}
            >
              {title}
            </h3>
            {accordions?.map(
              ({ _id, title, icon, accordionText }, accordionIndex) => (
                <Accordion
                  key={`${_id}-${accordionIndex}`}
                  index={index}
                  icon={icon}
                  title={title}
                >
                  <PortableText value={accordionText ?? []} />
                </Accordion>
              ),
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccordionSection;
