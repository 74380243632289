"use client";
import {
  useCarouselController,
  UseCarouselControllerConfig,
} from "@cappelendamm/design-system";
import classNames from "classnames";
import { PiCaretLeft, PiCaretRight } from "react-icons/pi";

import type { JSX } from "react";

export type CarouselProps = {
  config: UseCarouselControllerConfig;
  labels?: { next: string; previous: string };
  isAriaCarousel?: boolean;
};

export function Carousel({
  children,
  config,
  labels = { previous: "Forrige", next: "Neste" },
  id,
  ...rest
}: CarouselProps & JSX.IntrinsicElements["ul"]) {
  const { ref, onNext, onPrevious, nextEnabled, previousEnabled } =
    useCarouselController(config);
  return (
    <>
      <div className="grid grid-cols-2">
        <button
          className={classNames("flex h-12 w-12 items-center justify-center", {
            "opacity-30": !previousEnabled,
          })}
          disabled={!previousEnabled}
          onClick={onPrevious}
          aria-controls={id}
        >
          <PiCaretLeft />
          <span className="sr-only">{labels.previous}</span>
        </button>
        <button
          className={classNames("flex h-12 w-12 items-center justify-center", {
            "opacity-30": !nextEnabled,
          })}
          disabled={!nextEnabled}
          onClick={onNext}
          aria-controls={id}
        >
          <PiCaretRight />
          <span className="sr-only">{labels.next}</span>
        </button>
      </div>
      <ul {...rest} ref={ref}>
        {children}
      </ul>
    </>
  );
}
