"use client";
import {
  FC,
  createContext,
  useContext,
  useEffect,
  useId,
  useState,
  Dispatch,
  SetStateAction,
  ReactElement,
  type JSX,
} from "react";
import { SanityImage } from "@/cms/images/types";
import FocusLock from "react-focus-lock";
import { SanityReference } from "@sanity/image-url/lib/types/types";
import { usePathname } from "next/navigation";
import { LinkBlock } from "../Footer/footertypes";
import classNames from "classnames";

type HamburgerButtonProps = {
  className?: string;
  image?: SanityImage;
  linkBlock?: LinkBlock;
  moreInfo?: SanityReference[];
  icon: ReactElement<any>;
  buttonClassName?: string;
  testid?: string;
  arialabel?: string;
  parentMenuState?: boolean;
};

type HeaderMenu = { isOpen: boolean; level: number };

const MenuContext = createContext<{
  state: HeaderMenu;
  setState: Dispatch<SetStateAction<HeaderMenu>>;
}>({
  state: {
    isOpen: false,
    level: -1,
  },
  setState: () => undefined,
});

const useMenuContext = () => useContext(MenuContext);

export const MenuTop: FC<JSX.IntrinsicElements["button"]> = ({
  className,
  children,
}) => {
  const { setState } = useMenuContext();
  return (
    <button
      className={className}
      onClick={() => setState({ isOpen: false, level: -1 })}
      aria-label="Lukk meny"
    >
      {children}
    </button>
  );
};

export const Overlay: FC<JSX.IntrinsicElements["button"]> = ({ onClick }) => (
  <button
    className="absolute left-0 top-0 z-[11] h-screen w-[100vw] bg-[#272525] opacity-25"
    onClick={onClick}
  ></button>
);

export const MenuButton: FC<
  JSX.IntrinsicElements["button"] & Partial<HeaderMenu>
> = ({ level, isOpen, children, ...rest }) => {
  const { setState } = useMenuContext();

  return (
    <button
      {...rest}
      onClick={() => {
        setState((prev) => ({
          ...prev,
          level: level ?? prev.level,
          isOpen: isOpen ?? prev.isOpen,
        }));
      }}
    >
      {children}
    </button>
  );
};

export const SubMenu: FC<
  JSX.IntrinsicElements["div"] & Pick<HeaderMenu, "level">
> = ({ level, children, ...rest }) => {
  const { state } = useMenuContext();
  if (state.level === level) return <div {...rest}>{children}</div>;
  return null;
};

export const MenuNavigation: FC<
  HamburgerButtonProps & JSX.IntrinsicElements["div"]
> = ({
  className,
  children,
  icon,
  buttonClassName,
  testid,
  arialabel,
  parentMenuState,
}) => {
  const id = useId();
  const pathname = usePathname();
  const [menuState, setMenuState] = useState<HeaderMenu>({
    isOpen: false,
    level: -1,
  });

  useEffect(() => {
    if (parentMenuState)
      setMenuState((prev) => {
        document.body.style.overflow = "hidden";
        return { ...prev, isOpen: parentMenuState };
      });
  }, [parentMenuState]);

  useEffect(() => {
    setMenuState({ isOpen: false, level: -1 });
  }, [pathname]);

  useEffect(() => {
    if (!menuState.isOpen) document.body.style.overflow = "auto";
  }, [menuState.isOpen]);

  return (
    <MenuContext.Provider value={{ state: menuState, setState: setMenuState }}>
      <button
        className={classNames(buttonClassName)}
        id={id}
        onClick={() => {
          setMenuState((prev) => {
            return { ...prev, isOpen: true };
          });
          document.body.style.overflow = "hidden";
        }}
        aria-expanded={menuState.isOpen}
        data-testid={testid}
        aria-label={arialabel}
      >
        {icon}
      </button>
      {menuState.isOpen && (
        <FocusLock>
          <nav aria-labelledby={id} className={className}>
            <Overlay
              onClick={() => {
                setMenuState({ level: -1, isOpen: false });
                document.body.style.overflow = "auto";
              }}
            />
            {children}
          </nav>
        </FocusLock>
      )}
    </MenuContext.Provider>
  );
};
