"use client";

import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { PathParams } from "./types";
import { getCookie } from "@cappelendamm/cookie";
import { COOKIE_UNIVERSE } from "./routing";
import { filterPath } from "./filter-path";
import { EXPERIMENT_GROUP_COOKIE_NAME } from "@/experiment";

export const usePagePath = () => {
  const [state, setState] = useState<
    Pick<PathParams, "experimentGroup" | "universeTag">
  >({
    experimentGroup: "",
    universeTag: "",
  });
  const pathname = usePathname();
  useEffect(() => {
    const [universeTag, experimentGroup] = [
      getCookie(COOKIE_UNIVERSE),
      getCookie(EXPERIMENT_GROUP_COOKIE_NAME),
    ];
    setState({ universeTag, experimentGroup });
  }, [pathname]);
  const { universeTag, experimentGroup } = state;
  return filterPath({ experimentGroup, pathname, universeTag });
};
