import { SanityIcon } from "@/cms/icons/Icon";
import { FC, type JSX } from "react";
import classNames from "classnames";
import { IconList as IconListType } from "@/cms/sanity.types";

export type IconListProps = {
  value: IconListType;
  listItemClassName?: string;
};

export const IconList: FC<IconListProps & JSX.IntrinsicElements["ul"]> = ({
  value: { items },
  className,
  listItemClassName,
  ...rest
}) => {
  return (
    <ul
      className={classNames(
        "inline-flex flex-col gap-4 sm:flex-row",
        className,
      )}
      data-testid="icon-list"
      {...rest}
    >
      {items?.map(({ label, icon, _key }) => {
        if (!label || !icon) return null;
        return (
          <li
            key={_key}
            className={classNames(
              listItemClassName,
              "grid grid-cols-[auto_1fr] items-center gap-2",
            )}
          >
            {icon ? (
              <SanityIcon aria-hidden="true" data={{ ...icon }} size={24} />
            ) : null}
            <span className="text-left">{label}</span>
          </li>
        );
      })}
    </ul>
  );
};
