"use client";

import initTracker from "@cappelendamm/tracking";
import va from "@vercel/analytics";
import { getCookie } from "@cappelendamm/cookie";
import { flattenRecord } from "@/utils/object";
import { EXPERIMENT_GROUP_COOKIE_NAME } from "@/experiment";

/* window.dataLayer typing can go as far as this example: https://farrant.me/posts/typing-functions-which-might-not-exist */
declare global {
  interface Window {
    dataLayer?: Object[];
    [key: string]: any;
  }
}

const tracker = initTracker({
  debug: process.env.NEXT_PUBLIC_ENVIRONMENT !== "production",
  trackEvent: async (payload, { logger }) => {
    const windowDataLayerPush = (window.dataLayer ?? []).push(payload);
    if (payload.ecommerce) (window.dataLayer ?? []).push({ ecommerce: null });
    const { event, ...data } = payload;
    va.track(event, flattenRecord(data));
    logger.log(
      "Pushed event with payload: ",
      payload,
      "\nStatus: ",
      windowDataLayerPush,
    );
  },
  attachMeta: () => {
    try {
      const experimentGroup = getCookie(EXPERIMENT_GROUP_COOKIE_NAME);
      return { experimentGroup };
    } catch (e) {
      return {};
    }
  },
});

export default tracker;
