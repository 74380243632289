import { ImageBlock as ImageBlockTypes } from "../cms/types/objects";
import { FC, type JSX } from "react";
import classNames from "classnames";
import Image from "next/image";
import {
  getImageAspectRatio,
  getImageProps,
} from "../cms/images/getImageProps";
import { ImageCredit } from "./ImageCredit";

export type ImageBlockProps = {
  value: ImageBlockTypes;
};

export const ImageBlock: FC<
  ImageBlockProps & JSX.IntrinsicElements["figure"]
> = ({ value: { image }, className }) => {
  if (!image?.asset) return;
  const imageProps = getImageProps({
    ...image,
    ...getImageAspectRatio({
      height: image?.asset?.metadata?.dimensions?.height,
      aspectRatio: image?.asset?.metadata?.dimensions?.aspectRatio,
    }),
  });
  if (!imageProps) return;
  const credit = image.asset?.credit;
  return (
    <div id="wide" className="flex-col">
      <Image
        className={classNames(className, "sm:max-h-[500px] sm:w-full")}
        {...imageProps}
      />
      <ImageCredit credit={credit} className="" />
    </div>
  );
};
