import { getCookie } from "@cappelendamm/cookie";
import { EXPERIMENT_GROUP_COOKIE_NAME } from "@/experiment/constants";

export enum ExperimentGroup {
  A = "a",
  B = "b",
}

export const generateExperimentGroup = (): ExperimentGroup => {
  const randomValue = Math.random();
  return randomValue > 0.5 ? ExperimentGroup.A : ExperimentGroup.B;
};

export const getExperimentGroupFromCookie = (): ExperimentGroup | null => {
  const experimentGroup = getCookie(EXPERIMENT_GROUP_COOKIE_NAME);
  if (
    experimentGroup === ExperimentGroup.A ||
    experimentGroup === ExperimentGroup.B
  ) {
    return experimentGroup;
  }
  return null;
};
